import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Paper, Typography, Input, InputAdornment, TextField, Button, createStyles, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useGetBookingsQuery } from '../../Features/authActions';
import { useAuth } from '../../Hooks/useAuth';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import  CSVDownloadButton from '../../Components/CSVDownloadButton';
import CustomDatePicker from '../../Components/common/CustomDatePicker';
import { useForm, Controller } from 'react-hook-form'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {v4 as uuid} from 'uuid';
export default function Sales() {
    const [selected, selectedRow] = useState([]);
    const naigate = useNavigate();
    const { token } = useAuth();
    const [query, setQuery] = useState('');
    const [skip, setSkip] = useState(true);
    const filename =  `bookings-${moment().format('YYYY-MM-DD')}--${moment().format('h:mm:ss a')}`
    const { data, error, isLoading, isSuccess, isError } = useGetBookingsQuery({ token, query}, { skip });
    const [bookingsData, setBookingsData] = useState([]);
    const url = 'dashboard/user/id'
    const validationSchema = yup.object({
        from : yup.date().required('From date is required'),
        to : yup.date().required('To date is required')
    })
    const { watch, handleSubmit, control } = useForm({
        resolver: yupResolver(validationSchema)
    })
    const handleEdit = (params) => {
        // get the id of the row
        // redirect to the edit page
        // pass the id as a query param
        // console.log(params);
        // console.log(url + `/${params.id}`)
    }
    const handleSelectedRows = (data) => {
        // console.log(data)
        selectedRow(data);
    }
    const handleDelete = (e) => {
        e.preventDefault();
        // console.log(selected);
    }
    useEffect(() => {
        if( token.length > 0){
            setSkip(false);
        }
    }, [token])
    useEffect(() => {
        if (isError) {
            // console.log(error)
            toast.error('Something went wrong')
        }
    }, [isError])
    useEffect(() => {
        if( isSuccess){
            // console.log(data)
            let finalData = []
             data?.data?.forEach((item, upperIndex) => {
                item.orderDetails.forEach((order, index) => {
                    let tempObj =  {
                        id: uuid(),
                        email: item.email,
                        userId: item.userId,
                        paymentIntentId: item.paymentIntentId,
                        currency: item.currency,
                        createdAt: item.createdAt,
                        amount: item.amount,
                        status: item.status,
                        orderId : item.visId,
                        title : order.title,
                        total : order.total,
                        type : order.type,
                        // quantity : order.quantity,
                        visibleId : order.visibleId,
                    }
                    finalData.push(tempObj);
                })
                
            })
            setBookingsData(finalData);
            toast.success('Data fetched successfully')
        }
    }, [isSuccess, data])
    const handleShowBookingDetails = (data) => {
        naigate(`/dashboard/bookingdetails/${data.row.id}`)
    }
    const handleRangeSubmit = (data) => {
        // console.log(data);
        let from = moment(data.from).format('YYYY-MM-DD');
        let to = moment(data.to).format('YYYY-MM-DD');
        let queryStr = `?gte=${from}&lte=${to}`;
        // console.log(queryStr);
        // let bkgData = bookingsData.filter((item, index) => moment(item.createdAt).isBetween(from, to));
        // setBookingsData(bkgData);
         setQuery(queryStr);
    }
    const columns = [
       
       
        { field: "orderId" , headerName: "OrderId", width: 80 },
        {
            field: 'createdAt',
            headerName: 'Ordered On',
            description: 'This column has a value getter and is not sortable.',
            width: 200,
            renderCell: (params) => {
                return <Typography variant="body2" gutterBottom component="div">
                    {moment(params.value).tz("America/Los_Angeles").format('MMMM Do YYYY, h:mm:ss a')}
                </Typography>
            }
        }, 
        // { field: 'webinarType', headerName: 'Webinar Type', width: 300 },
        {
            field: 'email',
            headerName: 'Email',
            width: 350,
            editable: true,
        },
       
        {
            field: 'title',
            headerName: 'Title',
            width: 400,
            editable: true,
        },
        {
            field: 'total',
            headerName: 'Webinar Price',
            width: 150,
            editable: true,
        },
        // {
        //     field: 'quantity',
        //     headerName: 'Quantity',
        //     width: 150,
        //     editable: true,
        // },
        {
            field: 'visibleId',
            headerName: 'Visible Id',
            width: 100,
            editable: true,
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 120,
            editable: true,
        },
        {
            field: 'paymentIntentId',
            headerName: 'Payment Id',
            width: 200,
            editable: true,
        },
        {
            field: 'currency',
            headerName: 'Currency',
            width: 100,
        },{
            field: 'amount',
            headerName: 'Total Amt',
            width: 100,
            renderCell: (params) => {
                return <Typography variant="body2" gutterBottom component="div">
                    {'$ '+params.value}
                </Typography>
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return <Typography variant="body2" gutterBottom component="div">
                    {params.value === 'COMPLETED' ? <span style={{ color: 'green', fontWeight: 'bold' }}>{params.value.toUpperCase()}</span> : <span style={{ color: 'red', fontWeight: 'bold' }}>{params.value.toUpperCase()}</span>}
                </Typography>
            }
        },
        {
            field: 'Details',
            headerName: 'Details',
            width: 200,
            renderCell: (params) => {
                return <Button variant="contained" color="primary" onClick={() => handleShowBookingDetails(params)}>
                    Show Details
                </Button>
            }
        },
        { field: 'id', headerName: 'ID', width: 50 },
        {
            field: 'userId',
            headerName: 'UserId',
            width: 300,
            editable: true,
        }
    ];
    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Grid item container
                        sx={{ justifyContent: 'space-between' }} >
                        <Grid item >
                            <Typography variant="h6" gutterBottom component="div">
                                Sales
                            </Typography>
                        </Grid>
                        {/* <Grid sx={{ border: '1px solid red' }}>
                        
          </Grid>  */}
                        <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CSVDownloadButton data={bookingsData} filename={filename} />
                        </Grid>
                        {/* <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained" color="primary"
                                onClick={() => naigate(url)}
                            >
                                <AddIcon />
                            </Button>
                            </Grid>
          <Grid item xs={12} sm={3}>
                            <Button variant="contained"
                                color='error'
                                onClick={(e) => handleDelete(e)}
                            >
                                <DeleteIcon />
                            </Button>
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid  item container lg={12}>
                        
                            <Grid item container spacing={2} sx = {{
                                marginTop : '1rem',
                                display : 'flex',
                                justifyContent : 'left',
                                flexDirection : 'row'

                            }}>
                        <form onSubmit={handleSubmit(handleRangeSubmit)}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                gap: '1rem',
                                padding : '1rem'

                            }}>

                                <Grid item>
                                    <CustomDatePicker 
                                        control={control}
                                        name="from"
                                        label="From"
                                        maxWidth="100%"
                                    />

                                </Grid>
                                <Grid item >
                                    <CustomDatePicker
                                        control={control}
                                        name="to"
                                        label="To"
                                        maxWidth="100%"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" type="submit" sx = {{ backgroundColor : '#3f51b5', color : 'white'}}>
                                        Get This Range
                                    </Button>
                                </Grid>

                        </form>

                            </Grid>

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <DataGrid
                        rows={bookingsData}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 15,
                                },
                            }
                        }}
                        onRowSelectionModelChange={handleSelectedRows}
                        disableColumnSelector
                        pageSizeOptions={[15]}
                        // checkboxSelection
                        disableRowSelectionOnClick
                        // write style to make header cell background color and bold
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold',
                                color: 'white',
                                fontSize: '1rem',
                                textAlign: 'center',
                            },
                            '& .MuiDataGrid-menuIconButton': {
                                // make icon color white
                                color: 'white',
                                backgroundColor: 'transparent',
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            },
                            '& .MuiDataGrid-menuIcon': {
                                // make icon color white
                                color: 'white',
                                backgroundColor: 'transparent',
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            },
                            // chage color of sort icon color to white
                            '& .MuiDataGrid-sortIcon': {
                                color: 'white'
                            },
                            // remove cell border on  click
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none'
                            },
                            // remove hide column and manage columns
                            '& .MuiDataGrid-columnHeader' : {
                                backgroundColor : 'transparent'
                            },
                            height: '80vh',
                            "& .MuiDataGrid-virtualScrollerRenderZone": {
                                "& .MuiDataGrid-row": {
                                  "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                }
                              }
                              ,
                              "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: '#3f51b5',
                                color: "white",
                                fontSize: 16
                              }
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
}
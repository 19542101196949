import { useNavigate, useRoutes, useLocation, Navigate } from 'react-router-dom';
import { useJwt } from 'react-jwt'
import DashboardLayout from './Layouts/DashboardLayout';
import Login from './Pages/Auth/Login';
import Register from './Pages/Auth/Register';
import Userpage from './Pages/Userpage';
import WebinarDM from './Pages/DataManager/Webinar.DM';
import SpeakerDM from './Pages/DataManager/Speakers.DM';
import Sales from './Pages/Reports/Sales.Reports';
import Customers from './Pages/Reports/Customers.Reports';
import Subscribers from './Pages/Reports/Subscribers.Reports';
import NotFound from './Pages/NotFound/Notfound';
import CategoryForm from './Pages/Forms/Category.Form';
import WebinarForm from './Pages/Forms/Webinar.Form';
import WebinarUpdateForm from './Pages/Forms/WebinarUpdate.Form';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { isExpired } from "react-jwt";
import { authorize } from './Features/authSlice';
import { getAccessToken } from './utils/authCheck';
import { useRefreshtokenMutation } from './Features/authActions';
import { CommonBackdrop } from './Components/common/CommonBackdrop';
import Forgotpassword from './Pages/Auth/Forgotpassword';
import SpeakerForm from './Pages/Forms/Speaker.Form';
import SpeakerUpdateForm from './Pages/Forms/SpeakerUpdate.Form';
import Contact from './Pages/Reports/Contact.Reports';
import SpeakerOpp from './Pages/Reports/speakersOpp.Reports';
import  BookingDetails  from './Pages/BookingDetails';
import ManageUsers from './Pages/Forms/ManageUser.Form';
// import { getRefreshToken, verifyRefreshToken, verifyAccessToken, setRefreshToken, getAccessToken } from './utils/authCheck';

// create a private route based on auth state from redux and alsoo check if access tken present in session storage or not, if it is preesent then verify it
// if it is not present then get refresh token from local storage and verify it and then get access token from server and then add it to session storage and update redux state
const PrivateRoute = ({ element, ...rest }) => {
    const [isAuthorized, setIsAuthorized] = useState(false)
    const [getTokens, { data, isError, isLoading, isSuccess, error }] = useRefreshtokenMutation();
    const Navigating = useNavigate()
    const [isTokenVerified, setIsTokenVerified] = useState(false)

    const dispatch = useDispatch()
    const accessToken = sessionStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    useEffect(() => {
        //    Navigating('/login')
   
        if (accessToken) {
   
            const isAccessTokenExpired = isExpired(accessToken);
   
            if (isAccessTokenExpired) {
  
                if (refreshToken) {
            
                    const isRefreshTokenExpired = isExpired(refreshToken);
      
                    if (isRefreshTokenExpired) {
    
                        Navigating("/login");
                    }
                    else {
   
                        getTokens({
                            refreshToken: refreshToken,
                        });
                        dispatch(authorize({ data: { accessToken: accessToken } }));
                        setIsAuthorized(true)
                    }
                }
            }
            else {
                setIsAuthorized(true)
                dispatch(authorize({ data: { accessToken: accessToken } }));
            }
        }
        else if (refreshToken) {
            const isRefreshTokenExpired = isExpired(refreshToken);
            if (isRefreshTokenExpired) {
                Navigating("/login");
            }
            else {
                getTokens({
                    refreshToken: refreshToken,
                });
                setIsAuthorized(true)
                dispatch(authorize({ data: { accessToken: accessToken } }));
            }
        }
        else {
            Navigating("/login");
        }
    }, [])
    useEffect(() => {
        if (isSuccess) {
 
            dispatch(authorize({ data }))
            sessionStorage.setItem('accessToken', data.accessToken)
            localStorage.setItem('refreshToken', data.refreshToken)
        }
    }, [isSuccess])
    useEffect(() => {
        if (isError) {

            Navigating('/login')
        }
    }, [isError])
    return isAuthorized ? element : <CommonBackdrop open={true} />
}

// create a public route based on auth state from redux
const PublicRoute = ({ element, ...rest }) => {
    const isAuthorized = useSelector((state) => state.auth.isAuthenticated)
    return isAuthorized ? <Navigate to="/dashboard" replace /> : element
}
// create routes
const routes = [
    {
        path: '/dashboard',
        element: <PrivateRoute element={<DashboardLayout />} />,
        // use private route
        children: [
            { path: 'user', element: <PrivateRoute element={<Userpage />} /> },
            { path: 'datamanager/webinar', element: <PrivateRoute element={<WebinarDM />} /> },
            { path: 'datamanager/speaker', element: <PrivateRoute element={<SpeakerDM />} /> },
            { path: 'reports/sales', element: <PrivateRoute element={<Sales />} /> },
            { path: 'reports/customers', element: <PrivateRoute element={<Customers />} /> },
            { path: 'reports/subscribers', element: <PrivateRoute element={<Subscribers />} /> },
            { path: 'reports/contact', element: <PrivateRoute element={<Contact />} /> },
            { path: 'reports/speakers-opportunities', element: <PrivateRoute element={<SpeakerOpp />} /> },
            { path: 'forms/category', element: <PrivateRoute element={<CategoryForm />} /> },
            { path: 'forms/webinar/edit/:id', element: <PrivateRoute element={<WebinarUpdateForm />} /> },
            { path: 'forms/webinar', element: <PrivateRoute element={<WebinarForm />} /> },
            { path: 'forms/speaker/edit/:id', element: <PrivateRoute element={<SpeakerUpdateForm />} /> },
            { path: 'forms/speaker', element: <PrivateRoute element={<SpeakerForm />} /> },
            { path: 'bookingdetails/:id', element: <PrivateRoute element={<BookingDetails />} /> },
            { path: 'forms/manageusers', element: <PrivateRoute element={<ManageUsers />} /> },
            { path: '*', element: <Navigate to="/404" /> }
        ]
    },
    {
        path: '/',
        // element: <PublicRoute element={<Login />} />,
        children: [
            { path: 'login', element: <Login /> },
            { path: 'register', element: <Register /> },
            { path: 'forgotpassword', element: <Forgotpassword /> },
            { path: '404', element: <NotFound /> },
            { path: '/', element: <Navigate to="/login" /> },
            { path: '*', element: <Navigate to="/404" /> }
        ]
    }
];

// const routes = [
//     {
//         path: '/dashboard',
//         element: <DashboardLayout />,
//         children: [
//             { path: "user", element: <Userpage /> },
//             { path: "products", element: <Products /> },
//             { path: '*', element: <Navigate to="/404" /> }
//         ]
//     },
//     {
//         path: '/login',
//         element: <Login />,
//     },
//     {
//         path: '/register',
//         element: <Register />,
//     },
//     {
//         path: '/404',
//         element: <NotFound />,
//     },
//     {
//         path: "*",
//         element: <Navigate to="/404"  replace />
//     }
// ];

export default function Routes() {
    return useRoutes(routes);
}
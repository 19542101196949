import React from 'react'
import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Input from '../Components/Input'
import { Box, Paper } from '@mui/material'
import Button from '@mui/material/Button';
// import react-hook-form

// write css
import '../App.css'
const Userpage = () => {
  return (
    // create a cbox component xm has one filed and above sm has 2 filed in flex dipslay
    <Paper
      elevation={4}
      sx={{
        p: 4,
        margin: 'auto',
      }}
    >
      <Box
        component='div'
        className='BoxResp'
      >

        <Input
          lable='Name'
          placeholder='Enter your name'
          focused
        />
        <Input />
        <Input />
        <Input />
        <Input />
        <Input />
        <Input />
        <Input />

        <Button variant='contained' color='primary'
          onClick={() =>
            setTimeout(() => {
              alert('Your form is submitted')
            }, 1000)
          }
        >Submit</Button>
      </Box>
    </Paper>
  )
}

export default Userpage
//. read a pem file
import { useJwt } from 'react-jwt'
import axios from 'axios'
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY
const backendUrl = process.env.REACT_APP_BACKEND_URL
const backendUsername = process.env.REACT_APP_BACKEND_USERNAME
const backendPassword = process.env.REACT_APP_BACKEND_PASSWORD
const encryptedCredentials = btoa(`${backendUsername}:${backendPassword}`)
const getRefreshToken = async () => {
    try {
        // get refresh token from local storage
        const refreshToken = localStorage.getItem('refreshToken')
        if (!refreshToken) {
            return Promise.reject('No refresh token found')
        }
        return refreshToken
    }
    catch (err) {
        return Promise.reject(err)
    }
}
// let PUBLIC_KEY = "6LcZ8Y4cAAAAAHP5Z4Z4Z4Z4Z4Z4Z4Z4Z4Z4Z4Z4"

// const verifyRefreshToken = async (refreshToken) => {
//     try {
//         const decoded = useJwt.verify(refreshToken, PUBLIC_KEY)
//         if (!decoded) {
//             return new Error('Invalid refresh token')
//         }
//         const { exp } = decoded
//         if (Date.now() >= exp * 1000) {
//             return new Error('Refresh token expired')
//         }
//         return decoded
//     }
//     catch (err) {
//         return Promise.reject(err)
//     }
// }
// const verifyAccessToken = async (accessToken, verify) => {
//     try {
//         const decoded = verify(accessToken, PUBLIC_KEY)
//         if (!decoded) {
//             return new Error('Invalid access token')
//         }
//         const { exp } = decoded
//         if (Date.now() >= exp * 1000) {
//             return new Error('Access token expired')
//         }
//         return decoded
//     }
//     catch (err) {
//         return Promise.reject(err)
//     }
// }

const setRefreshToken = async (refreshToken) => {
    try {
        localStorage.setItem('refreshToken', refreshToken)
        return Promise.resolve()
    }
    catch (err) {
        return Promise.reject(err)
    }
}
const getAccessToken = async (refreshToken) => {
    try {
        const tokens = await axios.post(`${backendUrl}/auth/refreshtoken`, { refreshToken },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic` + encryptedCredentials
                }
            })
        return tokens
    }
    catch (err) {
        return Promise.reject(err)
    }
}
export { getRefreshToken, setRefreshToken, getAccessToken }
import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useController } from "react-hook-form";

const CustomCheckbox = ({ name, control, label, defaultValue , required, ...rest }) => {
    const {field : {ref, ...fieldProps}, fieldState: {invalid, error}} = useController({
        name,
        control,
        defaultValue,
        rules: {required}
    })
    return (
        <FormControlLabel
            control={
                <Checkbox
                    {...fieldProps}
                    {...rest}
                    inputRef={ref}
                    error={ toString(invalid)}
                />
            }
            label={label}
        />
    )
};

export default CustomCheckbox;
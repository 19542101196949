import { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Paper, Typography, Input, InputAdornment, TextField, Button, createStyles, Grid } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../../App.css'
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useGetSpeakerOppQuery } from '../../Features/authActions';
import { useAuth } from '../../Hooks/useAuth';
import { toast } from 'react-toastify';
import  CSVDownloadButton from '../../Components/CSVDownloadButton';
import CustomDatePicker from '../../Components/common/CustomDatePicker';
import { useForm, Controller } from 'react-hook-form'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from 'moment-timezone';

// function DetailsExpansionTable({details}) {
//     let billingDetails = !details ? null : details;
//     return (
//         <div>
//           {billingDetails &&
//             Object.keys(billingDetails).map((key, index) => (
//               <div key={index}>
//                 <table>
//                     <tbody>
//                         <tr>
//                             <td>{key}</td>
//                             <td>{billingDetails[key]}</td>
//                         </tr>
//                     </tbody>
//                 </table>
//               </div>
//             ))}
//         </div>
//       );
//   }
// const columns = [
   
//     {
//         field: 'name',
//         headerName: 'Name',
//         width: 150,
//         editable: false,
//     },
//     {
//         field: 'email',
//         headerName: 'Email',
//         width: 150,
//         editable: false,
//     },
//     {
//         field: 'message',
//         headerName: 'Message',
//         width: 500,
//         editable: false
//     },
//     {
//         field: 'createdAt',
//         headerName: 'Requested at',
//         width: 200,
//         editable: false,
//     }
    
// ]

const columns = [
    {
        dataField : 'name',
        text : 'Name'
    },
    {
        dataField : 'email',
        text : 'Email'
    },
   {
        dataField : 'phone',
        text : 'Phone'
   },
   {
        dataField : 'designation',
        text : 'Designation'
   },
   {
        dataField : 'yearsOfExperience',
        text : 'Years of experience'
   },
   {
        dataField : 'briefProfile',
        text : 'Brief profile'
   },
   {
        dataField : 'Contacted at',
        text : 'createdAt'
   }

]
    
export default function SpeakerOpp() {
    const { token } = useAuth();
    const [query, setQuery] = useState('');
    const { data, error, isLoading,refetch } = useGetSpeakerOppQuery({ token, query });
    const [selected, selectedRow] = useState([]);
    
    const naigate = useNavigate();
    const url = 'dashboard/user/id'
    const validationSchema = yup.object({
        from : yup.date().required('From date is required'),
        to : yup.date().required('To date is required')
    })
    const { watch, handleSubmit, control } = useForm({
        resolver: yupResolver(validationSchema)
    })
    const handleEdit = (params) => {
        // get the id of the row
        // redirect to the edit page
        // pass the id as a query param
        // console.log(params);
        // console.log(url + `/${params.id}`)
    }
    const handleSelectedRows = (data) => {
        // console.log(data)
        selectedRow(data);
    }
    const handleDelete = (e) => {
        // get all selected rows
        e.preventDefault();
        // console.log(selected);
    }
    useEffect(() => {
        // console.log(data);
    }, [data])
    // const handleRangeSubmit = (data) => {
    //     console.log(data);
    //     let from = moment(data.from).format('YYYY-MM-DD');
    //     let to = moment(data.to).format('YYYY-MM-DD');
    //     let queryStr = `?gte=${from}&lte=${to}`;
    //     console.log(queryStr);
    //     setQuery(queryStr);
    // }
    // useEffect(() => {
    //     refetch(token, query);
    // }, [query])
    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Grid item container
                        sx={{ justifyContent: 'space-between' }} >
                        <Grid item >
                            <Typography variant="h6" gutterBottom component="div">
                                Contacts
                            </Typography>
                        </Grid>
                        <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CSVDownloadButton data={data && data.data} />
                        </Grid>
                    </Grid>
                </Grid>
             
                 <Grid item xs={12} sm={12} md={12} lg={12}>
                    {data && data.data && (
                        <div className="table-container">
                        <table className="data-table">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Designation</th>
                                <th>Years of experience</th>
                                <th>Brief profile</th>
                                <th>Contacted At</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.data.map((item, index) => (
                                <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.phone}</td>
                                <td>{item.designation}</td>
                                <td>{item.yearsOfExperience}</td>
                                <td>{item.briefProfile}</td>
                                <td>{moment(item.createdAt).tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss A')}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                    )}
                </Grid>
            </Grid>
        </>
    );
}
import React from 'react'
import { TextField } from '@mui/material'
import { useController } from 'react-hook-form'

const CommonTextField = ({
    name,
    control,
    defaultValue = "",
    label,
    required = false,
    ...rest
}) => {
    const { field : { ref, ...fieldProps }, fieldState: { invalid, error } } = useController({
        name,
        control,
        defaultValue,
        rules: { required }
    })
    return (
        <TextField
        label={label}
        variant="outlined"
        inputRef={ref}
        error={invalid}
        helperText={error?.message}
        {...fieldProps}
        {...rest}
        />
    )
}

export default CommonTextField
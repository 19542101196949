import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// create auth apis using CreateAPi of reactredux/toolkit
// access env variables

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const backendLocalUrl = process.env.REACT_APP_BACKEND_URL_LOCAL;
const backendAdmin = process.env.REACT_APP_ADMIN_EMAIL
const backendPassword = process.env.REACT_APP_ADMIN_PASSWORD

export const authApi = createApi({
    // create auth apis using CreateAPi of reactredux/toolkit
    reducerPath: 'authApi',
    // create auth apis using CreateAPi of reactredux/toolkit
    baseQuery: fetchBaseQuery({ baseUrl:  backendUrl }),
    // create auth apis using CreateAPi of reactredux/toolkit
    endpoints: (builder) => ({
        login: builder.mutation({
            // set headers for login
            query: (data) => ({
                url: `/auth/login?admin=true`,
                method: 'POST',
                body: "",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(data.email + ':' + data.password),
                },
            }),
        }),
        register: builder.mutation({
            query: (data) => ({
                url: '/auth/register-admin',
                method: 'POST',
                body: data.payload,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + data.token
                },
            }),
        }),
        updatePassword: builder.mutation({
            query: (data) => ({
                url: '/auth/update-password',
                method: 'PATCH',
                body: data.payload,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + data.token
                },
            }),
        }),
        refreshtoken: builder.mutation({
            // set headers for refreshtoken
            query: (data) => ({
                url: '/auth/refreshtoken',
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/json',
                    // here we are using basic authentication with static sysadmin username and password
                    'Authorization': 'Basic ' + btoa(backendAdmin + ':' + backendPassword),
                },
            }),
        }),
        createWebinar: builder.mutation({
            // set headers for createWebinar
            query: (data) => ({
                url: '/webinar/create',
                method: 'POST',
                body: data.payload,
                // prepareHeaders: (headers, { getState }) => {
                //     const { auth } = getState();
                //     if(auth.token){
                //         headers.set('Authorization', `Bearer ${auth.token}`)
                //     }
                // }
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + data.token,
                }
            }),
        }),
        getWebinar: builder.query({
            query: ({ token, id }) => ({
                url: `/webinar/get?${id ? new URLSearchParams({ id: id }).toString()+'&limit=10000' : 'limit=10000'}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
        }),
        getSpeakers: builder.query({
            query: (data) => ({
                url: `/speakers/get?${data.id ? new URLSearchParams({ id: data.id }).toString() : ''}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + data.token,
                }
            })
        }),
        getCategories: builder.query({
            query: (data) => ({
                url: '/category/get',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + data.token,
                }
            })
        }),
        createCategory: builder.mutation({
            query: (data) => ({
                url: '/category/create',
                method: 'POST',
                body: data.payload,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + data.token,
                }
            })
        }),
        updateCategory: builder.mutation({
            query: ({ token, payload }) => ({
                url: '/category/update',
                method: 'PATCH',
                body: payload,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            })
        }),
        deleteCategory: builder.mutation({
            query: ({ token, payload }) => ({
                url: `/category/delete`,
                method: 'DELETE',
                body: payload,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            })
        }),
        updateWebinar: builder.mutation({
            query: ({ token, payload }) => ({
                url: '/webinar/update',
                method: 'PATCH',
                body: payload,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            })
        }),
        deleteWebinar: builder.mutation({
            query: ({ token, payload }) => ({
                url: '/webinar/delete',
                method: 'DELETE',
                body: payload,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            })
        }),
        createSpeaker: builder.mutation({
            query: ({ token, payload, headers }) => ({
                url: '/speakers/create',
                method: 'POST',
                body: payload,
                headers: {
                    ...headers,
                    'Authorization': 'Bearer ' + token,
                }
            })
        }),
        updateSpeaker: builder.mutation({
            query: ({ token, payload, headers }) => ({
                url: '/speakers/update',
                method: 'PATCH',
                body: payload,
                headers: {
                    ...headers,
                    'Authorization': 'Bearer ' + token,
                }
            })
        }),
        deleteSpeaker: builder.mutation({
            query: ({ token, payload}) => ({
                url: '/speakers/delete',
                method: 'DELETE',
                body: payload,
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            })
        }),
        enableSpeaker: builder.mutation({
            query: ({ token, payload}) => ({
                url: '/speakers/enable',
                method: 'PATCH',
                body: payload,
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            })
        }),
        getAllUsers: builder.query({
            query: ({ token, query }) => ({
                url: `/user/allcustomers${query ? query : ''}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
        }),
        getEmailSubscribers: builder.query({
            query: ({ token, query }) => ({
                url: `/user/email_subscribers`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
        }),
        getBookings : builder.query({
            query: ({ token, query }) => ({
                url: `/bookings/get?${query ? new URLSearchParams(query).toString() : ''}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
        }),
        getContactUs: builder.query({
            query: ({ token, query }) => ({
                url: `/contact/get`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
        }),
        getSpeakerOpp: builder.query({
            query: ({ token, query }) => ({
                url: `/speakeropportunity/get`,
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
        }),

    }),
});
export const { useLoginMutation, useRefreshtokenMutation, useCreateWebinarMutation, useGetWebinarQuery, useGetSpeakersQuery,
    useGetCategoriesQuery, useCreateCategoryMutation, useUpdateCategoryMutation, useDeleteCategoryMutation, useUpdateWebinarMutation,
    useDeleteWebinarMutation, useCreateSpeakerMutation , useUpdateSpeakerMutation, useDeleteSpeakerMutation,useEnableSpeakerMutation,
    useGetAllUsersQuery, useGetEmailSubscribersQuery, useGetBookingsQuery,
    useGetContactUsQuery, useGetSpeakerOppQuery, useRegisterMutation, useUpdatePasswordMutation
} = authApi;

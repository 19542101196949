// create a custume text input file with material ui
import React from 'react';
import { TextField } from '@mui/material'

const Input = ({ name, label, value, error = null, onChange, ...other }) => {
    return (
        <TextField
        id='outlined-basic'
        variant='outlined'
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        {...other}
        {...(error && { error: true, helperText: error })}
        />
    );
    }

export default Input;
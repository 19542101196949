import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Paper, Typography, Input, InputAdornment, TextField, Button, createStyles, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useGetAllUsersQuery } from '../../Features/authActions';
import { useAuth } from '../../Hooks/useAuth';
import { toast } from 'react-toastify';
import  CSVDownloadButton from '../../Components/CSVDownloadButton';
import CustomDatePicker from '../../Components/common/CustomDatePicker';
import { useForm, Controller } from 'react-hook-form'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from 'moment-timezone';
// function DetailsExpansionTable({details}) {
//     let billingDetails = !details ? null : details;
//     return (
//         <div>
//           {billingDetails &&
//             Object.keys(billingDetails).map((key, index) => (
//               <div key={index}>
//                 <table>
//                     <tbody>
//                         <tr>
//                             <td>{key}</td>
//                             <td>{billingDetails[key]}</td>
//                         </tr>
//                     </tbody>
//                 </table>
//               </div>
//             ))}
//         </div>
//       );
//   }
const columns = [
    // {
    //     field: 'id',
    //     headerName: 'ID',
    //     width: 100,
    //     editable: false,

    // },
    {
        field: 'id',
        headerName: 'ID',
        width: 300,
        editable: false
    },
    {
        field: 'firstName',
        headerName: 'First name',
        width: 150,
        editable: false,
    },
    {
        field: 'lastName',
        headerName: 'Last name',
        width: 150,
        editable: false,
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 200,
        editable: false,
    },
    {
        field: 'phoneNumber',
        headerName: 'Phone number',
        width: 200,
        editable: false,
    },
    {
        field: 'country',
        headerName: 'Country',
        width: 200,
        editable: false,
    },
    {
        field: 'company',
        headerName: 'Company',
        width: 200,
        editable: false,
    },
    {
        field: 'role',
        headerName: 'Role',
        width: 200,
        editable: false,
    },
    {
        field: 'createdAt',
        headerName: 'Joined at',
        width: 350,
        editable: false,
        renderCell: (params) => (
            <div>
               {moment(params.value).tz("America/Los_Angeles").format('MMMM Do YYYY, h:mm:ss a')}
            </div>
        )

    }
    // {
    //     field: 'billingDetails',
    //     headerName: 'Billing details',
    //     width: 200,
    //     editable: false,
    //     height: 'auto',
    //     renderCell: (params) => (
    //         <div>
    //         <DetailsExpansionTable details={params.row && params.row.billingDetails} />
    //         </div>
    //       ),
    // }
]
export default function Customers() {
    const { token } = useAuth();
    const [query, setQuery] = useState('');
    const { data, error, isLoading,refetch } = useGetAllUsersQuery({ token, query });
    const [selected, selectedRow] = useState([]);
    const naigate = useNavigate();
    const url = 'dashboard/user/id'
    const validationSchema = yup.object({
        from : yup.date().required('From date is required'),
        to : yup.date().required('To date is required')
    })
    const { watch, handleSubmit, control } = useForm({
        resolver: yupResolver(validationSchema)
    })
    const handleEdit = (params) => {
        // get the id of the row
        // redirect to the edit page
        // pass the id as a query param
        // console.log(params);
        // console.log(url + `/${params.id}`)
    }
    const handleSelectedRows = (data) => {
        // console.log(data)
        selectedRow(data);
    }
    const handleDelete = (e) => {
        // get all selected rows
        e.preventDefault();
        // console.log(selected);
    }
    useEffect(() => {
        // console.log(data);
    }, [data])
    const handleRangeSubmit = (data) => {
        // console.log(data);
        let from = moment(data.from).format('YYYY-MM-DD');
        let to = moment(data.to).format('YYYY-MM-DD');
        let queryStr = `?gte=${from}&lte=${to}`;
        // console.log(queryStr);
        setQuery(queryStr);
    }
    // useEffect(() => {
    //     refetch(token, query);
    // }, [query])
    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Grid item container
                        sx={{ justifyContent: 'space-between' }} >
                        <Grid item >
                            <Typography variant="h6" gutterBottom component="div">
                                Customers
                            </Typography>
                        </Grid>
                        {/* <Grid sx={{ border: '1px solid red' }}>

          </Grid>  */}
                        <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CSVDownloadButton data={data && data.data} />
                            {/* <Button variant="contained" color="primary"
                                onClick={() => naigate(url)}
                            >
                                <AddIcon />
                            </Button> */}
                            {/* </Grid>
          <Grid item xs={12} sm={3}> */}
                            {/* <Button variant="contained"
                                color='error'
                                onClick={(e) => handleDelete(e)}
                            >
                                <DeleteIcon />
                            </Button> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid  item container lg={12}>
                        
                            <Grid item container spacing={2} sx = {{
                                marginTop : '1rem',
                                display : 'flex',
                                justifyContent : 'left',
                                flexDirection : 'row'

                            }}>
                        <form onSubmit={handleSubmit(handleRangeSubmit)}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                gap: '1rem',
                                padding : '1rem'

                            }}>

                                <Grid item>
                                    <CustomDatePicker 
                                        control={control}
                                        name="from"
                                        label="From"
                                        maxWidth="100%"
                                    />

                                </Grid>
                                <Grid item >
                                    <CustomDatePicker
                                        control={control}
                                        name="to"
                                        label="To"
                                        maxWidth="100%"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" type="submit" sx = {{ backgroundColor : '#3f51b5', color : 'white'}}>
                                        Get This Range
                                    </Button>
                                </Grid>

                        </form>

                            </Grid>

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                   {
                         data && data.data && <DataGrid
                        rows={data.data}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 15,
                                },
                            }
                        }}
                        onRowSelectionModelChange={handleSelectedRows}
                        disableColumnSelector
                        pageSizeOptions={[15]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        // write style to make header cell background color and bold
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold',
                                color: 'white',
                                fontSize: '1rem'
                            },
                            '& .MuiDataGrid-menuIconButton': {
                                // make icon color white
                                color: 'white',
                                backgroundColor: 'transparent',
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            },
                            '& .MuiDataGrid-menuIcon': {
                                // make icon color white
                                color: 'white',
                                backgroundColor: 'transparent',
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            },
                            // chage color of sort icon color to white
                            '& .MuiDataGrid-sortIcon': {
                                color: 'white'
                            },
                            // remove cell border on  click
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none'
                            },
                            // remove hide column and manage columns
                            '& .MuiDataGrid-columnHeader' : {
                                backgroundColor : 'transparent'
                            },
                            height: '80vh',
                            "& .MuiDataGrid-virtualScrollerRenderZone": {
                                "& .MuiDataGrid-row": {
                                  "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                }
                              }
                              ,
                              "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: '#3f51b5',
                                color: "white",
                                fontSize: 16
                              }
                        }}
                    />
                     }
                </Grid>
            </Grid>
        </>
    );
}
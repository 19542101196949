import { Button, Grid, Paper, TextField, Typography } from "@mui/material";

import { Link } from "react-router-dom";
import RHTextField from "../../Components/common/CommonTextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { styled } from "@mui/material/styles";

const useStyles = styled((theme) => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container1: {
    // display: "flex",
    // flexDirection: "column",

    color: "white",
    padding: theme.spacing(2),
    borderRadius: "5px",
  },
  inputGrid: {
    paddingBottom: theme.spacing(2),
  },
  rootPaper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
  },
}));


  
const Register = () => {
  const classes = useStyles();

  const registerSchema = yup.object().shape({
    name: yup.string().required("name is required"),
    email: yup.string().required("email is required"),
    password: yup.string().required("password is required"),
    confirmPassword: yup.string().required("This field is required"),
  });

  const formMethods = useForm({
    resolver: registerSchema && yupResolver(registerSchema),
  });

  const onSubmit = (data) => console.log(data);

  return (
    <FormProvider {...formMethods}>
      <Paper
        sx={{
          p: 2,
          margin: "auto",
        }}
        style={{ width: "20vw", marginTop: "200px" }}
      >
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Grid
            container
            xs={12}
            lg={12}
            md={12}
            className={classes.container1}
            spacing={2}
          >
            <Grid item xs={12} lg={12} md={12}>
              <RHTextField label={"name"} name="name" fullWidth />
            </Grid>
            <Grid item xs={11} lg={12} md={12}>
              <RHTextField label={"email"} name="email" fullWidth />
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <RHTextField
                label={"password"}
                type="password"
                name={"password"}
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <RHTextField
                label={"confirm password"}
                type="confirm password"
                name={"confirm password"}
              />
            </Grid>
            <Grid
              item
              direction="row"
              justifyContent="center"
              alignItems="center"
              xs={11}
              lg={11}
              md={11}
              className={classes.inputGrid}
            >
              <Button
                fullWidth={true}
                variant="outlined"
                style={{ color: "white", backgroundColor: "#3f51b5" }}
                onClick={formMethods.handleSubmit(onSubmit)}
              >
                Create Account
              </Button>
            </Grid>
            <Grid item xs={11} lg={11} md={11} className={classes.inputGrid}>
              <Typography
                align="center"
                style={{
                  fontSize: "10px",
                  letterSpacing: "1px",
                  color: "black",
                }}
              >
                {`AREADY HAVE AN ACCOUNT? `}
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  <Link to="/">Login</Link>
                </span>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </FormProvider>
  );
};

export default Register;
import { useEffect } from 'react'
import { Paper, Grid, Typography, Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import CommonTextField from '../../Components/common/CommonTextField'
import { toast } from 'react-toastify'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRegisterMutation, useUpdatePasswordMutation } from '../../Features/authActions';
import { useAuth } from '../../Hooks/useAuth';

export default function ManageUsers() {
    const validationSchema = yup.object({
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
        email: yup.string().email().required('Email is required'),
        password: yup.string().required('Password is required'),
        country: yup.string().optional(),
        phoneNumber: yup.string().optional(),
        company: yup.string().optional()
    })
    const updatePasswordSchema = yup.object({
        currentPassword: yup.string().required('Current Password is required'),
        newPassword: yup.string().required('New Password is required'),
        confirmPassword: yup.string().required('Confirm Password is required').oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    })
    const { handleSubmit: handlePasswordChange, control: passwordControl } = useForm({
        resolver: yupResolver(updatePasswordSchema)
    })
    const { handleSubmit, control } = useForm({
        resolver: yupResolver(validationSchema)
    })
    const { token } = useAuth()
    const [register, { data, error, isLoading, isSuccess }] = useRegisterMutation()
    const [updatePassword, { data: passwordData, error: passwordError, isLoading: passwordLoading, isSuccess: passwordSuccess }] = useUpdatePasswordMutation()
    const handleNewUser = (data) => {
        const payload = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
            country: data.country,
            phoneNumber: data.phoneNumber,
            company: data.company,
            role: 'admin'
        }
        register({ payload, token })
    }
    const handlePasswordSubmit = (data) => {
        const payload = {
            currentPassword: data.currentPassword,
            newPassword: data.newPassword,
            confirmPassword: data.confirmPassword
        }
        updatePassword({ payload, token })
    }
    useEffect(() => {
        if (isSuccess) {
            toast.success('Admin created successfully')
        }
        if (error) {
            toast.error('An error occured')
        }
        if (isLoading) {
            toast.info('Loading...')
        }
    }, [isSuccess, error, isLoading])
    useEffect(() => {
        if (passwordSuccess) {
            toast.success('Password changed successfully')
        }
        if (passwordError) {
            toast.error('An error occured')
        }
        if (passwordLoading) {
            toast.info('Loading...')
        }
    }, [passwordSuccess, passwordError, passwordLoading])
    return (
        <div>
            <Paper elevation={3} sx={{height: '100vh'}}>
                <Grid container spacing={2} sx={{ padding: '1rem' }}>
                    <Grid item xs={12} lg={12} md={12} sx={{ margin: 'auto', textAlign: 'center' }}>
                        <Typography variant='h4'>Manage Users</Typography>
                        <hr />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sx={{ margin: 'auto', marginTop: '1rem' }}>
                        <Typography variant='h5'>Create An Admin</Typography>
                        <form>
                            <Grid item container xs={12} lg={10} md={10}  spacing={2} sx={{ marginTop: '1rem' }}>
                                <Grid item xs={12} lg={6} md={6}>
                                    <CommonTextField
                                        control={control}
                                        name='firstName'
                                        label='First Name *'
                                        type='text'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6} md={6}>
                                    <CommonTextField
                                        control={control}
                                        name='lastName'
                                        label='Last Name *'
                                        type='text'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6} md={6}>
                                    <CommonTextField
                                        control={control}
                                        name='email'
                                        label='Email *'
                                        type='email'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6} md={6}>
                                    <CommonTextField
                                        control={control}
                                        name='password'
                                        label='Password *'
                                        type='password'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6} md={6}>
                                    <CommonTextField
                                        control={control}
                                        name='country'
                                        label='Country'
                                        type='text'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6} md={6}>
                                    <CommonTextField
                                        control={control}
                                        name='phoneNumber'
                                        label='Phone Number'
                                        type='text'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6} md={6}>
                                    <CommonTextField
                                        control={control}
                                        name='company'
                                        label='Company'
                                        type='text'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <Button variant='contained' color='primary' onClick={handleSubmit(handleNewUser)}>Create Admin</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sx={{ margin: 'auto', marginTop: '1rem' }}>
                        <Typography variant='h5'>Change Password</Typography>
                        <form>
                            <Grid item container xs={12} lg={10} md={10}  spacing={2} sx={{ marginTop: '1rem' }}>
                                <Grid item xs={12} lg={6} md={6}>
                                    <CommonTextField
                                        control={control}
                                        name='currentPassword'
                                        label='Current Password *'
                                        type='email'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6} md={6}>
                                    <CommonTextField
                                        control={control}
                                        name='newPassword'
                                        label='Password *'
                                        type='password'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6} md={6}>
                                    <CommonTextField
                                        control={control}
                                        name='confirmPassword'
                                        label='Confirm Password*'
                                        type='password'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <Button variant='contained' color='primary' onClick={handleSubmit(handlePasswordSubmit)}>Change Password</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}
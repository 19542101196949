import React, { useEffect } from 'react'
import { Paper, Grid, Button, Typography } from '@mui/material'
import CommonTextField from '../../Components/common/CommonTextField'
import CommonAutoComplete from '../../Components/common/CommonAutoComplete'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../Hooks/useAuth'
import { toast } from 'react-toastify'
// useQuery
import { useQuery } from 'react-query'
import { useCreateCategoryMutation, useUpdateCategoryMutation, useDeleteCategoryMutation, useGetCategoriesQuery } from '../../Features/authActions'
import { ref } from 'yup'
const CategoryForm = () => {
  const { control, handleSubmit, watch, setValue } = useForm()
  const { token } = useAuth()
  const [createCategory, { data: createCategoryData, isSuccess: createCategorySuccess, isLoading: createCategoryLoading, isError: createCategoryError }] = useCreateCategoryMutation()
  const [updateCategory, { data: updateCategoryData, isSuccess: updateCategorySuccess, isLoading: updateCategoryLoading, isError: updateCategoryError }] = useUpdateCategoryMutation()
  const [deleteCategory, { data: deleteCategoryData, isSuccess: deleteCategorySuccess, isLoading: deleteCategoryLoading, isError: deleteCategoryError }] = useDeleteCategoryMutation()
  const { data, isSuccess, isLoading, isError, refetch } = useGetCategoriesQuery({ token: token })
  const watchAllFields = watch()

  const handleCreateCategory = () => {
    createCategory({ token: token, payload: { name: watchAllFields.categoryName } })
    setTimeout(() => {
      refetch()
    }, 1000)
  }
  const removeDuplicateFields = () => {
    const unique = data?.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i)
    return unique
  }
  const handleUpdateCategory = () => {
    updateCategory({ token: token, payload: { id: watchAllFields.FetchedCategoryName.id, name: watchAllFields.categoryUpdateName } })
    setTimeout(() => {
      refetch()
    }, 1000)
  }
  const handleDeleteCategory = () => {
    deleteCategory({ token: token, payload: { id: watchAllFields.FetchedCategoryName.id } })
    setTimeout(() => {
      refetch()
    }, 1000)
  }
  // useEffect(() => {
  //   if (createCategorySuccess || updateCategorySuccess || deleteCategorySuccess) {
  //     if (toast.isActive('success' || 'error' || 'info')) {
  //       toast.dismiss()
  //     }
  //     toast.success('Success', { autoClose: 1000 })
  //   }
  //   else if (createCategoryError || updateCategoryError || deleteCategoryError) {
  //     if (toast.isActive('success' || 'error' || 'info')) {
  //       toast.dismiss()
  //     }
  //     toast.error('Something went wrong', { autoClose: 1000 })
  //   }
  //   else if (createCategoryLoading || updateCategoryLoading || deleteCategoryLoading) {
  //     if (toast.isActive('success' || 'error' || 'info')) {
  //       toast.dismiss()
  //     }
  //     toast.info('Loading', { autoClose: 1000 })
  //   }
  // }, [createCategorySuccess, updateCategorySuccess, deleteCategorySuccess, createCategoryError, updateCategoryError, deleteCategoryError, createCategoryLoading, updateCategoryLoading, deleteCategoryLoading])

  useEffect(() => {
    if(createCategorySuccess){
      toast.success('Success', { autoClose: 1000 })
    }
    else if(createCategoryError){
      toast.error('Something went wrong', { autoClose: 1000 })
    }
    else if(createCategoryLoading){
      toast.info('Loading', { autoClose: 1000 })
    }
  }
  , [createCategorySuccess, createCategoryError, createCategoryLoading])
  useEffect(() => {
    if(updateCategorySuccess){
      toast.success('Success', { autoClose: 1000 })
    }
    else if(updateCategoryError){
      toast.error('Something went wrong', { autoClose: 1000 })
    }
    else if(updateCategoryLoading){
      toast.info('Loading', { autoClose: 1000 })
    }
  }
  , [updateCategorySuccess, updateCategoryError, updateCategoryLoading])
  useEffect(() => {
    if(deleteCategorySuccess){
      toast.success('Success', { autoClose: 1000 })
    }
    else if(deleteCategoryError){
      toast.error('Something went wrong', { autoClose: 1000 })
    }
    else if(deleteCategoryLoading){
      toast.info('Loading', { autoClose: 1000 })
    }
  }
  , [deleteCategorySuccess, deleteCategoryError, deleteCategoryLoading])
  return (
    <Paper
      elevation={4}
      sx={{
        p: 4,
        margin: "auto",
      }}
      style={{ width: "90%", }}
    >
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
      >
        <Typography variant='h5' sx={{ textAlign: 'center', margin: 'auto' }}>Create Category</Typography>
        <Grid item container xs={12} lg={12} md={12} spacing={2} sx={{ alignItems: 'center' }} >
          <Grid item xs={12} lg={6} md={6}>
            <CommonTextField
              name="categoryName"
              control={control}
              label="Category Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6}>
            <Button label='update' variant='contained' color='primary' type='submit' sx={{ width: '8rem', height: '3.2rem', fontSize: '1.5rem', fontWeight: 'bold' }} onClick={handleCreateCategory}>Create</Button>
          </Grid>
        </Grid>
        <Typography variant='h5' sx={{ textAlign: 'center', margin: 'auto', marginTop: '3rem' }}>Update Category</Typography>
        <Grid item container xs={12} lg={12} md={12} spacing={2} sx={{ alignItems: 'center' }} >
          <Grid item xs={12} lg={5} md={5}>
            <CommonAutoComplete
              name="FetchedCategoryName"
              control={control}
              label="Category Name"
              getOptionLabel={(option) => option.name}
              options={removeDuplicateFields()}
              fullWidth
            />

          </Grid>
          <Grid item xs={12} lg={5} md={5}>
            <CommonTextField
              name="categoryUpdateName"
              control={control}
              label="Update Category Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={8} lg={1} md={1}>
            <Button label='update' variant='contained' color='primary' type='submit' sx={{ width: '8rem', height: '3.2rem', fontSize: '1.5rem', fontWeight: 'bold' }} onClick={handleUpdateCategory}>Update</Button>
          </Grid>
        </Grid>
        <Typography variant='h5' sx={{ textAlign: 'center', margin: 'auto', marginTop: '3rem' }}>Delete Category</Typography>
        <Grid item container xs={12} lg={12} md={12} spacing={2} sx={{ alignItems: 'center' }} >
          <Grid item xs={12} lg={6} md={6}>
            <CommonAutoComplete
              name="FetchedCategoryName"
              control={control}
              label="Category Name"
              getOptionLabel={(option) => option.name}
              options={removeDuplicateFields()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6}>
            <Button label='delete' variant='contained' color='primary' type='submit' sx={{ width: '8rem', height: '3.2rem', fontSize: '1.5rem', fontWeight: 'bold' }}
              onClick={handleDeleteCategory}
            >Delete</Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CategoryForm
import { Outlet } from "react-router-dom"
import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Button } from "@mui/material";
import Navcollapse from "../Components/Navcollapse";
import { useAuth } from "../Hooks/useAuth";
const drawerWidth = 240;

function DashboardLayout(props) {
    const Navigate = useNavigate();
    const location = useLocation();
    // const [auth, setAuth] = React.useState(true)

    const handleLogout = () => {
        sessionStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken');
        Navigate('/login')
    }
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const navConfig = [
        {
            title: 'Products',
            path: '/dashboard/products',
            icon: <InboxIcon />,
            submenu: [
                {
                    title: 'Product 1',
                    path: '/dashboard/products',
                    icon: <InboxIcon />
                },
                {
                    title: 'Product 2',
                    path: '/dashboard/products1',
                    icon: <InboxIcon />
                }
            ]
        },
        {
            title: 'User',
            path: '/dashboard/user',
            icon: <InboxIcon />,
            submenu: [
                {
                    title: 'Product 1',
                    path: '/dashboard/products',
                    icon: <InboxIcon />
                },
                {
                    title: 'Product 2',
                    path: '/dashboard/products',
                    icon: <InboxIcon />
                }
            ]
        }
    ]
    const handleRouting = (path) => {
        // console.log(location);
        Navigate(path);
    }
    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <List>
                {/* {['products', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text}>
                               <Link to="/products">Products</Link>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                ))} */}
                {/* {navConfig.map((item, index) => (
                    <ListItem key={item.title} disablePadding  */}
                {/* style={{backgroundColor: location.pathname === item.path ? 'lightgreen' : ''}}
                    > */}
                {/* <Accordion
                      >
                        <AccordionSummary
                            expandIcon={<InboxIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                                style={{ backgroundColor: location.pathname === item.path ? '#e0e0e0' : '' , 
                                width: '100%'
                            }}
                        > 
                        <ListItemText primary={item.title}/>

                        </AccordionSummary>
                        <AccordionDetails>
                            <List>
                                {item.submenu.map((subitem, index) => (
                                    <ListItem key={subitem.title} disablePadding onClick={() => handleRouting(subitem.path)}  
                                    >
                                        <ListItemText primary={subitem.title} style={{ backgroundColor: location.pathname === subitem.path ? 'lightgreen' : '' }} />
                                    </ListItem>
                                ))}
                            </List>
                        </AccordionDetails>
                      </Accordion> */}
                {/* </ListItem> */}
                {/* ))} */}
                <Navcollapse />
            </List>

        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    background: 'linear-gradient(90deg, rgba(2,0,36,0.8912158613445378) 8%, rgba(63,81,181,0.8491990546218487) 30%, rgba(9,9,121,0.8744091386554622) 70%);',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '0 20px'
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                    </Typography>
                </Toolbar>
                <Typography variant="h4"
                    fontWeight="bold"
                    sx={{ color: '#fff' }}
                    fontSize='1.6rem'
                >
                    Dashboard
                </Typography>
                <Box>

                    <Button variant="contained" color='error' onClick={() => handleLogout()}>Logout</Button>
                </Box>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#3f51b5' }
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            backgroundColor: '#3f51b5',
                            // color: '#000'
                        }
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    );
}


export default DashboardLayout;
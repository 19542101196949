import React, { useEffect } from 'react';
import { useAuth } from '../Hooks/useAuth';
import { useGetBookingsQuery } from '../Features/authActions';
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Grid, Typography, Button, Paper } from '@mui/material'
export  const BookingDetails = () => {
    const locaton = useLocation()
    const bookingId = locaton.pathname.split('/').pop()
    const { token } = useAuth();
    const { data, error, isLoading, isSuccess, isError } = useGetBookingsQuery({ token, id: bookingId });
    useEffect(() => {
        if (isError) {
            toast.error('Error in fetching booking details')
        }
        if (isLoading) {
            toast.info('Fetching booking details')
        }
        if (isSuccess) {
            toast.success('Successfully fetched booking details')
        }
    }, [isError, isLoading, isSuccess])
    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper>
                    <div style={{padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap : '10px'}}>
                    <Typography variant='h3' style={{margin: 'auto'}}>Booking Details</Typography>
                    <ul style={{ fontSize: '1.2rem', fontWeight: 'bold', padding: '5px' }}>
                        <li>Booking Id: {data?.data[0].id}</li>
                        <li>User Id: {data?.data[0].userId}</li>
                        <li>Email: {data?.data[0].email}</li>
                        <li>Payment Intent Id: {data?.data[0].paymentIntentId}</li>
                        <li>Amount: {data?.data[0].amount}</li>
                        <li>Currency: {data?.data[0].currency}</li>
                        <li>Customer: {data?.data[0].customer}</li>
                        <li>Description: {data?.data[0].description}</li>
                        <li>Payment Method: {data?.data[0].payment_method}</li>
                        <li>Status: {data?.data[0].status}</li>
                        <li>Created At: {data?.data[0].createdAt}</li>
                        <li>Webinar Type: {data?.data[0].webinarType}</li>
                        <li>Webinar Id: {data?.data[0].webinarId}</li>
                        <li>Visible Id: {data?.data[0].visibleId}</li>
                    </ul>
                    <Typography variant='h4' style={{margin: 'auto'}}>Order Details</Typography>

                    {
                        data?.data[0].orderDetails.map((order, index) => {
                            return (
                                <div key={index}>
                                    <Typography variant='h5' style={{margin: 'auto'}}>Webinar {index + 1}</Typography>
                                    <ul style={{ fontSize: '1.2rem', fontWeight: 'bold', padding: '5px' }}>
                                        <li>Type: {order.type}</li>
                                        <li>Title: {order.title}</li>
                                        <li>Total: {order.total}</li>
                                        <li>User Id: {order.userId}</li>
                                        <li>Quantity: {order.quantity}</li>
                                        <li>Timezone: {order.timezone}</li>
                                        <li>Visible Id: {order.visibleId}</li>
                                        <li>Webinar Id: {order.webinarId}</li>
                                        <li>Webinar Date: {order.webinarDate}</li>
                                        <li>Webinar Time: {order.webinarTime}</li>
                                    </ul>
                                </div>
                            )
                        })
                    }
                    </div>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default BookingDetails
import React from 'react';
import Papa from 'papaparse';
import { Button } from '@mui/material';
function CSVDownloadButton({ data, filename }) {
  const handleDownload = () => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename + '.csv';
    link.click();
  };

  return (
    // <button onClick={handleDownload}>Download CSV</button>
    <Button variant="contained"
        sx={
            {
                backgroundColor: 'green',
                color: 'white',
                '&:hover': {
                    backgroundColor: '#32cd32',
                    color: 'white',
                    cursor: 'pointer'
                }
            }
        }
     onClick={handleDownload}>
        Download CSV
    </Button>
  );
}

export default CSVDownloadButton;

import {
    Autocomplete,
    Checkbox,
    FormControlLabel,
    Grid,
    Input,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { v4 } from "uuid";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useState, useEffect } from "react";
import CommonAutoComplete from "../../Components/common/CommonAutoComplete";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import CommonTextField from '../../Components/common/CommonTextField'
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useLocation } from "react-router-dom";
import { CommonReactQuill } from '../../Components/CommonReactQuill';
import CustomDatePicker from '../../Components/common/CustomDatePicker';
import CustomTimePicker from '../../Components/common/CustomTimePicker';
import CustomCheckbox from "../../Components/common/CustomCheckbox";
import { useAuth } from "../../Hooks/useAuth";
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import { useGetCategoriesQuery, useGetWebinarQuery, useCreateWebinarMutation, useGetSpeakersQuery } from "../../Features/authActions";
// const GetWebinarById = (token, id) => {
//     const { data, isLoading, isError, isSuccess } = useGetWebinarQuery({ token: token, id: id })
//     return { data, isLoading, isError, isSuccess }
// }
const WebinarForm = () => {
    const navigate = useNavigate()
    const navigatePath = '/dashboard/datamanager/webinar?refetch=true'
    const [quilldata, setQuilldata] = useState('')
    const [speakerDetails, setSpeakersData] = useState([])
    const [speakerProfile, setSpeakerProfile] = useState({})
    const [categories, setCategories] = useState([])
    const [defaultFormValues, setDefaultFormValues] = useState({
        webinarName: ''
    })
    const { token } = useAuth()
    const [createWebinar, { data, isLoading, isError, isSuccess }] = useCreateWebinarMutation()
    const speakersData = useGetSpeakersQuery({ token: token })
    const categoriesData = useGetCategoriesQuery({ token: token })
    useEffect(() => {
        if (isSuccess) {
            toast('Webinar Created', { type: 'success' })
            navigate(navigatePath)
            reset()
        }
        else if (isError) {
            toast('Error', { type: 'error' })
        }
        else if (isLoading) {
            toast('Loading', { type: 'info', autoClose: true })
        }
    }, [isLoading, isSuccess, data, isError])
    useEffect(() => {
        let data = speakersData.data?.data
        let categories = categoriesData.data
        if (typeof data !== 'undefined' && data.length > 0) {
            setSpeakerProfile(data)
            setSpeakersData(data)
        }
        if (typeof categories !== 'undefined' && categories.length > 0) {
            setCategories(categories)
        }
    }, [speakersData, categoriesData])

    const validationSchema = yup
        .object({
            webinarId: yup.string().required(),
            webinarName: yup.string().required(),
            speakername: yup.object().required(),
            category: yup.object().required(),
            WebinarDate: yup.string().required(),
            WebinarTime: yup.string().required(),
            duration: yup.string().required(),
            TimeZone: yup.string().required(),
            overview: yup.string().required(),
            why_you_should_attend: yup.string().required(),
            who_will_benefit: yup.string().required(),
            areasCovered: yup.string().required(),
        })
        .required();


    const { watch, handleSubmit, control, setValue,reset} = useForm({
        resolver: validationSchema && yupResolver(validationSchema),
    });
    const watchAllFields = watch();
    const generateRandomId = () => {
        return v4();
    }
    useEffect(() => {
        if (watchAllFields.speakername !== undefined) {
            setSpeakerProfile(watchAllFields.speakername)
        }
    }, [watchAllFields.speakername])
    const submitForm = (data) => {
        let webinarURLTitle = data.webinarName.split(' ').join('-')
        webinarURLTitle = webinarURLTitle.toLowerCase()+'-'+data.webinarId
        let payload = {
            visibleId: data.webinarId,
            title: data.webinarName,
            speakerName: data.speakername.name,
            speakerId: data.speakername.id,
            category: data.category.name,
            categoryId: data.category.id,
            type: data.Live && data.Recorded ? 'combo' : data.Live ? 'live' : 'recorded',
            duration: +data.duration,
            webinarDate: data.WebinarDate,
            webinarTime: data.WebinarTime,
            timezone: data.TimeZone,
            SHRM: data.SHRM,
            HRCI: data.HRCI,
            liveOnePrice: +data['Live-one-participant-price'],
            liveGroupPrice: +data['Live-group-participant-price'],
            recordedOnePrice: +data['Recorded-one-participant-price'],
            recordedGroupPrice: +data['Recorded-group-participant-price'],
            comboOnePrice: +data['Combo-one-participant-price'],
            comboGroupPrice: +data['Combo-group-participant-price'],
            overview: data.overview,
            whyAttend: data.why_you_should_attend,
            areasCovered: data['areasCovered'],
            whoWillBenefit: data.who_will_benefit,
            webinarURLTitle : webinarURLTitle
        }
        payload.webinarDate = moment(payload.webinarDate).format('YYYY-MM-DD')
        payload.webinarTime = moment(payload.webinarTime).format('HH:mm:ss')
        createWebinar({ payload: payload, token: token })
    }

    // const { watch, handleSubmit} = formMethods;

    const onError = (error) => {
        console.log('error', error);
    };
    // const handleQuill = (data) => {
    //     setQuilldata(data)
    //     console.log(quilldata)
    //     console.log(data)
    //     // set data to the form 
    //     // formMethods.setValue('overview', data)
    // }
    return (
        // <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitForm, onError)}>
            <Paper
                elevation={4}
                sx={{
                    p: 4,
                    margin: "auto",
                }}
                style={{ width: "90%", }}
            >
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                    <Grid item container xs={12} lg={12} md={12} spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                            <CommonTextField label="webinar Id" control={control} name="webinarId" required  fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <CommonTextField label="webinar name" control={control} name="webinarName" defaultValue={defaultFormValues.webinarName} required fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} lg={12} md={12} spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                            <CommonAutoComplete
                                label="Speakername"
                                name="speakername"
                                // include array of  objects but only take the name
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                getOptionLabel={(option) => option.name}
                                options={speakerDetails}
                                required
                                control={control}
                            // onChange={(e) => {
                            //     console.log(e)
                            // }}

                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <CommonAutoComplete label="category" control={control} name="category"
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                getOptionLabel={(option) => option.name}
                                options={categories} />
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} md={12} lg={12} spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                            <DemoItem>
                                {/* <DatePicker defaultValue={dayjs("2022-04-17T15:30")} onChange={(newValue) => setValue(newValue)} /> */}
                                <CustomDatePicker label="WebinarDate" control={control} name="WebinarDate" required />
                            </DemoItem>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <DemoItem>
                                {/* <MobileTimePicker defaultValue={dayjs("2022-04-17T15:30")} /> */}
                                <CustomTimePicker label="WebinarTime" control={control} name="WebinarTime" required />
                            </DemoItem>
                        </Grid>
                    </Grid>

                    {/* <Grid item container xs={12} md={12} lg={6}>
                            <Grid
                                item
                                xs={3}
                                md={3}
                                lg={3}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Typography>{"Duration :"}</Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={6}
                                lg={6}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                              //  <CommonTextField label='duration' type='number' name='duration' required/>
                            </Grid>
                        </Grid> */}

                    {/* <Grid item container xs={12} md={12} lg={6} spacing={2}>
                            <Grid
                                item
                                xs={3}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Typography>{"Webinar Type :"}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel control={<Checkbox />} label="Live" />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel control={<Checkbox />} label="Rocorded" />
                            </Grid>
                        </Grid> */}

                    <Grid item container xs={12} md={12} lg={12} spacing={2}>
                        <Grid item container xs={12} md={6} lg={6} spacing={2}>
                            <Grid item xs={12} md={8} lg={8}>
                                {/* <FormControlLabel control={<Checkbox />} label="PST/EST" /> */}
                                <CommonAutoComplete label="TimeZone" control={control} name="TimeZone" options={['PST', 'EST', 'PDT', 'EDT']} />
                                {/* <FormControlLabel control={<Checkbox />} label="PDT/EDT" /> */}
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <CommonTextField label='duration' control={control} type='number' name='duration' required fullWidth />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6} lg={6} spacing={2}>
                            <Grid item xs={12} md={6} lg={6} >
                                <CustomCheckbox label="Live" control={control} name="Live" />
                                <CustomCheckbox label="Recorded" control={control} name="Recorded" />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <CustomCheckbox label="SHRM" control={control} name="SHRM" />
                                <CustomCheckbox label="HRCI" control={control} name="HRCI" />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} style={{ margin: 'auto', textAlign: 'center' }}>
                        <Typography
                            variant="h6"
                            style={{ fontWeight: 'bold' }}>
                            {"PRICES"}</Typography>
                    </Grid>
                    <Grid item container xs={12} md={12} lg={12} spacing={2}>
                        <Grid item container xs={12} md={6} lg={6} spacing={2} style={{ textAlign: 'center', alignItems: 'center' }}>
                            <Grid item xs={12} md={2} lg={2} >
                                <Typography>{"Live"}</Typography>
                            </Grid>
                            <Grid item xs={6} md={5} lg={5}>
                                <CommonTextField control={control} label='one-participant' type='number' name='Live-one-participant-price' required fullWidth />
                            </Grid>
                            <Grid item xs={6} md={5} lg={5}>
                                <CommonTextField control={control} label='group-participant' type='number' name='Live-group-participant-price' required fullWidth />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6} lg={6} spacing={2} style={{ textAlign: 'center', alignItems: 'center' }}>
                            <Grid item xs={12} md={2} lg={2} >
                                <Typography>{"Recording"}</Typography>
                            </Grid>
                            <Grid item xs={6} md={5} lg={5}>
                                <CommonTextField control={control} label='one-participant' type='number' name='Recorded-one-participant-price' required fullWidth />
                            </Grid>
                            <Grid item xs={6} md={5} lg={5}>
                                <CommonTextField control={control} label='group-participant' type='number' name='Recorded-group-participant-price' required fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={12} lg={12} spacing={2}>
                        <Grid item container xs={12} md={6} lg={6} spacing={2} style={{ textAlign: 'center', alignItems: 'center' }}>
                            <Grid item xs={12} md={2} lg={2} >
                                <Typography>{"Combo-"}</Typography>
                            </Grid>
                            <Grid item xs={6} md={5} lg={5}>
                                <CommonTextField control={control} label='one-participant' type='number' name='Combo-one-participant-price' required fullWidth />
                            </Grid>
                            <Grid item xs={6} md={5} lg={5}>
                                <CommonTextField control={control} label='group-participant' type='number' name='Combo-group-participant-price' required fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12} style={{ margin: 'auto', textAlign: 'center' }}>
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                            {"Overview"}
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} md={12} lg={12} spacing={2} style={{ marginTop: '0.5rem', padding: '0 0 0 2rem' }}>
                        <CommonReactQuill name="overview" control={control} placeholder='overview...' id='overview' />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} style={{ margin: 'auto', textAlign: 'center' }}>
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                            {"Why Should Attend"}
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} md={12} lg={12} spacing={2} style={{ marginTop: '0.5rem', padding: '0 0 0 2rem' }}>
                        < CommonReactQuill name="why_you_should_attend" control={control} placeholder='Why you should attend...' id='why_you_should_attend' />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} style={{ margin: 'auto', textAlign: 'center' }}>
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                            {"Areas covered in this Session"}
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} md={12} lg={12} spacing={2} style={{ marginTop: '0.5rem', padding: '0 0 0 2rem' }}>
                        < CommonReactQuill name="areasCovered" control={control} placeholder='Areas covered in this session ...' id='areas-covered' />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} style={{ margin: 'auto', textAlign: 'center' }}>
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                            {"Who will Benefit"}
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} md={12} lg={12} spacing={2} style={{ marginTop: '0.5rem', padding: '0 0 0 2rem' }}>
                        <CommonReactQuill name="who_will_benefit" control={control} placeholder='Who Will benefit...' id='who_will_benefit' />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} style={{ margin: 'auto', textAlign: 'center' }}>
                        {/* <img src={speakerProfile.profileImg} alt="speaker" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                            {speakerProfile.bio}
                        </Typography> */}
                        {speakerProfile && speakerProfile.profileImg && <img src={speakerProfile.profileImg} alt="speaker" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />}
                        {speakerProfile && speakerProfile.bio && parse(speakerProfile.bio)}
                    </Grid>
                    <Grid item xs={3} style={{ margin: 'auto' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            type="submit"
                            style={{ marginTop: '1rem' }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </form>

    );
}

export default WebinarForm
// import { Input, Button } from "@mui/material"
// import { useEffect, useState } from "react"
// import { useLoginMutation } from "../../Features/authActions"
// import { useSelector, useDispatch } from "react-redux"
// import { authorize } from "../../Features/authSlice"
// import { useNavigate } from "react-router-dom"
// import { useCookies } from "react-cookie"
// const Login = () => {
//   const isAuthorized = useSelector((state) => state.auth.isAuthenticated)
//   const dispatch = useDispatch()
//   const navigate = useNavigate()
//   const [cookies, setCookie, removeCookie] = useCookies(['refreshToken'])
//   const [formData, setFormData] = useState({
//     username: '',
//     password: ''
//   })
//   const handleFormChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value
//     })
//   }
//   // get all options from useLoginMutation
//   const [login, { data, isError, isLoading, isSuccess, error }] = useLoginMutation();
//   useEffect(() => {
//     if (isError) {
//       console.log(error)
//     }
//   }, [isLoading]);
//   useEffect(() => {
//     if (isSuccess) {
//       console.log(data)
//       dispatch(authorize({ data }))
//       console.log(isAuthorized)
//       // setcookie for 7 days and httpOnly
//       setCookie('refreshToken', data.refreshToken, { path: '/' })
//       navigate('/dashboard/user')
//     }
//   }, [isSuccess, isAuthorized]);
//   const handleSubmit = () => {
//     console.log(formData)
//     //  send to  form data  login as headers authorization basic username password
//     login(formData)
//   }
//   return (
//     <div>
//       <Input onChange={handleFormChange} name='username' />
//       <br />
//       <Input onChange={handleFormChange} name='password' />

//       <Button variant='contained' color='primary' onClick={() => {
//         handleSubmit()
//       }} >Submit</Button>

//     </div>
//   )
// }

// export default Login

import { Button, Grid, Paper, TextField, Typography, InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import RHTextField from "../../Components/common/CommonTextField";
import { useEffect, useState } from "react"
import { useLoginMutation } from "../../Features/authActions"
import { useSelector, useDispatch } from "react-redux"
import { authorize } from "../../Features/authSlice"
import { useNavigate } from "react-router-dom"
import { setRefreshToken } from "../../utils/authCheck";
import CommonToastify from "../../Components/common/CommonToastify";
import { toast } from "react-toastify";


const Login = () => {
  const [showPassword, setShowPassword] = useState(false)
  const isAuthorized = useSelector((state) => state.auth.isAuthenticated)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [login, { data, isError, isLoading, isSuccess, error }] = useLoginMutation();
  useEffect(() => {
    if (isError) {
      // <CommonToastify message={error} type='error' />
      toast.error("Email or password or wrong")
    }
  }, [isError]);
  useEffect(() => {
    if (isSuccess) {
      toast.success('successfully loggedin')
      dispatch(authorize({ data }))
      sessionStorage.setItem('accessToken', data.accessToken)
      setRefreshToken(data.refreshToken)
      navigate('/dashboard/forms/webinar')
    }
  }, [isSuccess])
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const loginSchema = yup.object().shape({
    email: yup.string().required("email is required"),
    password: yup.string().required("password is required"),
  });

  const formMethods = useForm({
    resolver: loginSchema && yupResolver(loginSchema),
  });

  const onSubmit = (data) => {
    login(data,true)
  }

  return (
    <Grid container spacing={2} style={{ padding: '2%', margin: 'auto', alignItems: 'center', marginTop: '10%' }}>
      <Grid item xs={12} lg={5} md={5} style={{ padding: '2%', margin: 'auto', alignItems: 'center', width: '20%' }}>
        <FormProvider {...formMethods}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              margin: "auto",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" align="center" sx={{marginBottom:'1rem',fontWeight:'bold'}}>
              Login
            </Typography>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Grid
                container
                spacing={3}
              >
                <Grid item xs={12} lg={12} md={12}>
                  <RHTextField
                    label={"email"}
                    name="email"
                    variant="outlined"                
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                  <RHTextField
                    label="password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6} md={6} sx={{margin:'auto'}}>
                  <Button
                    fullWidth={true}
                    style={{ color: "white", backgroundColor: "#3f51b5" }}
                    onClick={formMethods.handleSubmit(onSubmit)}
                  >
                    Login
                  </Button>
                </Grid>
                {/* <Grid item xs={12} lg={12} md={12}>
                  <Typography
                    align="center"
                    style={{
                      fontSize: "12px",
                      letterSpacing: "2px",
                      color: "black",
                    }}
                  >
                    {`DON'T REMEMBER PASSWORD? `}
                    <span
                      style={{
                        fontSize: "1rem",
                        // fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      <Link to="/forgotpassword">forgotpassword</Link>
                    </span>
                  </Typography>
                </Grid> */}
              </Grid>
            </form>
          </Paper>
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default Login;

import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = () => {
      window.history.go(1);
    };
  }, []);
  
  return (
    <div style = {{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
      margin : '0 auto',
      flexDirection: 'column',
      backgroundColor: '#00d4ff',
    }}>
     <h1 style = {{
        fontSize: '5rem',
        fontWeight: 'bold',
        color : '#fff'
      }}>
        404 Not Found
     </h1>
     <p style = {{
        fontSize: '2rem',
        fontWeight: 'bold',
        color : '#fff'
      }}>
       <a href = '/dashboard/reports/sales' style = {{ color: '#fff' }}>Go to Home</a>
      </p>
    </div>
  );
};

export default NotFound;
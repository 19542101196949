import React from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { useController } from 'react-hook-form'
const CommonAutoComplete = ({
    name,
    control,
    label = "",
    options = [],
    getOptionLabel = (option) => option,
    required = false,
    ...rest
}) => {
    const { field: { ref, ...fieldProps }, fieldState: { invalid, error } } = useController({
        name,
        control,
        rules: { required }
    })
    return (
        <Autocomplete
            options={options}
            getOptionLabel={getOptionLabel}
            onChange={(e, data) => fieldProps.onChange(data)}
            {...rest}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    inputRef={ref}
                    error={invalid}
                    helperText={error?.message}
                    {...fieldProps}
                    {...rest}
                />
            )}
        />
    )
}

export default CommonAutoComplete
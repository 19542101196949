import {createSlice} from '@reduxjs/toolkit';
const initialState = {
    userInfo : {},
    userToken : null,
    error : null,
    isAuthenticated : false
}

export const authSlice = createSlice({
    name : 'auth',
    initialState,
    reducers : {
        authorize : (state, action) => {
            state.isAuthenticated = true
            state.userToken = action.payload.data.accessToken
        }
    },
})
export const {authorize} = authSlice.actions;
export default authSlice.reducer
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./Features/authSlice";
import { authApi } from "./Features/authActions";
const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        auth: authReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(authApi.middleware),
    
});

export default store;
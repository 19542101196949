import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useController } from "react-hook-form";
import { TextField } from "@mui/material";

const CustomDatePicker = ({
    name,
    control,
    defaultValue = "",
    label,
    required = false,
    ...rest
}) => {
    const {
        field: { ref, ...fieldProps },
        fieldState: { invalid, error },
    } = useController({
        name,
        control,
        defaultValue,
        rules: { required },
    });
    return (
        <DatePicker
            label={label}
            inputRef={ref}
            onChange={(e) => fieldProps.onChange(e)}
            {...rest}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={invalid}
                    helperText={error?.message}
                    {...fieldProps}
                />
            )}
        />
    );
}

export default CustomDatePicker;
// // create a custom  auth middleware hook which checks for access token in session storage and refresh token in local storage
// // adn send a request to server to get new access token if access token is expired and refresh token is not expired
// // if refresh token is also expired then redirect to login page
// //  use rtk query to send request to server to get new access token
// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { useRefreshtokenMutation } from "../Features/authActions"
// import { authorize } from "../Features/authSlice"
// import { isExpired, decodeToken} from "react-jwt";

// export const useAuth = () => {
//     const isAuthorized = useSelector((state) => state.auth.isAuthenticated);
//     const [getTokens, { data, isError, isLoading, isSuccess, error }] =
//         useRefreshtokenMutation();
//     const navigating = useNavigate();
//     const dispatch = useDispatch();
//     const [token, setToken] = useState("");
//     const accessToken  = sessionStorage.getItem("accessToken");
//     const refreshToken = localStorage.getItem("refreshToken");
//     useEffect(() => {
//         if(accessToken){
//             console.log('inside access token')
//             const isAccessTokenExpired = isExpired(accessToken);
//             console.log(isAccessTokenExpired)
//             if(isAccessTokenExpired){
//                 console.log('inside is expired')
//                 if(refreshToken){
//                     console.log('inside refresh token')
//                     const isRefreshTokenExpired = isExpired(refreshToken);
//                     console.log(isRefreshTokenExpired)
//                     if(isRefreshTokenExpired){
//                         console.log('inside refresh token expired')
//                         navigating("/login");
//                     }
//                     else {
//                         console.log('inside refresh token not expired')
//                         getTokens({
//                             refreshToken: refreshToken,
//                         });
//                         dispatch(authorize({ data: { accessToken: accessToken } }));
//                         setToken(sessionStorage.getItem("accessToken"));
//                     }
//                 }
//             }
//             else {
//                 setToken(sessionStorage.getItem("accessToken"));
//                 dispatch(authorize({ data: { accessToken: accessToken } }));
//             }
//         }
//         else if(refreshToken){
//             const isRefreshTokenExpired = isExpired(refreshToken);
//             if(isRefreshTokenExpired){
//                 navigating("/login");
//             }
//             else {
//                 getTokens({
//                     refreshToken: refreshToken,
//                 });
//                 setToken(sessionStorage.getItem("accessToken"));
//                 dispatch(authorize({ data: { accessToken: accessToken } }));
//             }
//         }
//         else {
//             navigating("/login");
//         }
//     }, []);
    
//     useEffect(() => {
//         if (isSuccess && data.accessToken) {
//             console.log('inside is success')
//             sessionStorage.setItem("accessToken", data.accessToken);
//             dispatch(authorize({ data: { accessToken: data.accessToken } }));
//             setToken(sessionStorage.getItem("accessToken"));
//         }
//     }, [isSuccess]);

//    return { token }
// };

// // create a function to check whether the token is expired or not

// create a custom  auth middleware hook which checks for access token in session storage and refresh token in local storage
// adn send a request to server to get new access token if access token is expired and refresh token is not expired
// if refresh token is also expired then redirect to login page
//  use rtk query to send request to server to get new access token
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useRefreshtokenMutation } from "../Features/authActions"
import { authorize } from "../Features/authSlice"
import { isExpired, decodeToken} from "react-jwt";

export const useAuth = () => {
    const isAuthorized = useSelector((state) => state.auth.isAuthenticated);
    const [getTokens, { data, isError, isLoading, isSuccess, error }] =
        useRefreshtokenMutation();
    const navigating = useNavigate();
    const dispatch = useDispatch();
    const [token, setToken] = useState("");
    const accessToken  = sessionStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    useEffect(() => {
        if(accessToken){
            // console.log('inside access token')
            const isAccessTokenExpired = isExpired(accessToken);
            // console.log(isAccessTokenExpired)
            if(isAccessTokenExpired){
                // console.log('inside is expired')
                if(refreshToken){
                    // console.log('inside refresh token')
                    const isRefreshTokenExpired = isExpired(refreshToken);
                    // console.log(isRefreshTokenExpired)
                    if(isRefreshTokenExpired){
                        // console.log('inside refresh token expired')
                        navigating("/login");
                    }
                    else {
                        // console.log('inside refresh token not expired')
                        getTokens({
                            refreshToken: refreshToken,
                        });
                        dispatch(authorize({ data: { accessToken: accessToken } }));
                        setToken(sessionStorage.getItem("accessToken"));
                    }
                }
            }
            else {
                setToken(sessionStorage.getItem("accessToken"));
                dispatch(authorize({ data: { accessToken: accessToken } }));
            }
        }
        else if(refreshToken){
            const isRefreshTokenExpired = isExpired(refreshToken);
            if(isRefreshTokenExpired){
                navigating("/login");
            }
            else {
                getTokens({
                    refreshToken: refreshToken,
                });
                setToken(sessionStorage.getItem("accessToken"));
                dispatch(authorize({ data: { accessToken: accessToken } }));
            }
        }
        else {
            navigating("/login");
        }
    }, []);
    
    useEffect(() => {
        if (isSuccess && data.accessToken) {
            // console.log('inside is success')
            sessionStorage.setItem("accessToken", data.accessToken);
            dispatch(authorize({ data: { accessToken: data.accessToken } }));
            setToken(sessionStorage.getItem("accessToken"));
        }
    }, [isSuccess]);

   return { token }
};

// create a function to check whether the token is expired or not

export const useJwt = (token) => {  
    const [decodedToken, setDecodedToken] = useState("");
    const [isExpired, setIsExpired] = useState(false);
    const [reEvaluateToken, setReEvaluateToken] = useState(false);
    useEffect(() => {
        if (token) {
            const decoded = decodeToken(token);
            setDecodedToken(decoded);
            const isExpired = decodeToken(token).exp < Date.now() / 1000;
            setIsExpired(isExpired);
            setReEvaluateToken(false);
        }
    }, [token]);

    return { decodedToken, isExpired, reEvaluateToken };
}

import{useEffect, useRef, useState}from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Paper, Typography,Input, InputAdornment, TextField, Button, createStyles, Grid} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useLocation} from 'react-router-dom';
import { useGetWebinarQuery, useRefreshtokenMutation, useUpdateWebinarMutation, useDeleteWebinarMutation} from '../../Features/authActions.js';
import { useSelector, useDispatch } from "react-redux"
import { getRefreshToken } from '../../utils/authCheck.js';
import  { useAuth } from '../../Hooks/useAuth.js';
import {Switch} from '@mui/material';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
// import { useAuth } from '../../Hooks/useAuth.js';


 
export default function Webinar() { 
  const [selected, selectedRow] = useState([]);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryObj = {}
  location.search.split('?').forEach(query => {
    if(!query.length) {
      return
    }
    const qr = query.split('=')
    queryObj[qr[0]] = qr[1]
  })
   // const { api } = useAuth(useGetWebinarQuery)
  // const { data, isLoading, isError } = useGetWebinarQuery(api);
  // console.log(data)
  const [webinarsData, setWebinarsData] = useState([])
  const { token } = useAuth();  
  const { data, isLoading, isError, refetch } = useGetWebinarQuery({token: token});
  const [updateWebinar, { isLoading: updateLoading, isError: updateError, isSuccess: updateSuccess }] = useUpdateWebinarMutation();
  const [deleteWebinar, { isLoading: deleteLoading, isError: deleteError, isSuccess: deleteSuccess }] = useDeleteWebinarMutation();

  useEffect(()=> {
    if(queryObj?.refetch == true){
      refetch()
    }
  },[queryObj])
  useEffect(() => {
    if (data) {
      let webData = data.rows.map((item) => {
        return {
          id: item.id,
          visibleId: item.visibleId,
          title: item.title,
          speakerName: item.speakerName,
          type: item.type,
          webinarDate: item.webinarDate.split('T')[0],
          webinarTime: moment(item.webinarTime, 'HH:mm:ss').format('hh:mm A'),
          duration: item.duration,
          enabled: item.enabled,
        }
      })
      setWebinarsData(webData);
    }
  }, [data]);
  useEffect(() => {
    if (updateSuccess) {
      toast.success('Webinar updated successfully');
    }
    else if(updateError) {
       toast.error('Something went wrong, please try again later');
    }
    else if(deleteSuccess) {
      toast.success('Webinar deleted successfully');
    }
    else if(deleteError) {
      toast.error('Something went wrong, please try again later');
    }
  }, [updateSuccess, updateError, deleteSuccess, deleteError])
  const handleEnable = (params) => {
    let data = {
      id: params.id,
      enabled: !params.enabled
    }
    updateWebinar({ token: token, payload: data })
    setTimeout(() => {
      refetch();
    }, 1000);
  }

  const handleEdit = (params) => {
    Navigate(`/dashboard/forms/webinar/edit/${params.id}`)
  }
  const handleSelectedRows = (data)=> {
    selectedRow(data);
  }
  const handleDelete = (e) => {
    e.preventDefault();
    deleteWebinar({ token: token, payload: selected })
    refetch();
  }
  const columns = [
    // { field: 'id', headerName: 'ID', width: 300 },
    {
      field: 'enabled',
      headerName: 'Enabled',
      width: 100,
      renderCell: (params) => {
        return <Switch checked={params.row.enabled} onChange={() => handleEnable(params.row)} />
      }
    },
   
    {
      field: 'Actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => {
        return <Button variant="contained" color="primary" size='small'
          sx={{ width: '5px' }} onClick={() => handleEdit(params)}
        >
          <EditIcon />
        </Button>
      }
    },
    {
      field: 'visibleId',
      headerName: 'Webinar id',
      width: 150,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 700,
      editable: true,
    },
    {
      field: 'speakerName',
      headerName: 'Speaker',
      width: 200,
      editable: true,
    },
    {
      field: 'type',
      headerName: 'Type',
      // sortable: false,
      width: 100,
      // valueGetter: (params) =>
      //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    }, {
      field: 'webinarDate',
      headerName: 'Webinar Date',
      width: 150
    }, {
      field: 'webinarTime',
      headerName: 'Webinar Time',
      width: 150
    }, 
    {
      field: 'duration',
      headerName: 'Duration',
      // type : 'number',
      width: 120
    }, 
  ];
  return (
    <>
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Grid item container 
         sx={{justifyContent:'space-between'}} >
            <Grid item >
            <Typography variant="h6" gutterBottom component="div">
              Webinars
            </Typography>
          </Grid>
            {/* <Grid sx={{ border: '1px solid red' }}>

          </Grid>  */}
            <Grid item  sx={{ display: 'flex', justifyContent: 'flex-end'}}>
            <Button variant="contained" color="primary"
             onClick={() => Navigate('/dashboard/forms/webinar')}
            >
              <AddIcon />
            </Button>
          {/* </Grid>
          <Grid item xs={12} sm={3}> */}
            <Button variant="contained"
            color='error'
              onClick={(e)=> handleDelete(e)}
            >
              <DeleteIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
          {webinarsData && <DataGrid
            rows={webinarsData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15,
                },
              }
            }}
            onRowSelectionModelChange={handleSelectedRows}
            disableColumnSelector
            pageSizeOptions={[15]}
            checkboxSelection
            disableRowSelectionOnClick
            // write style to make header cell background color and bold
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                  color: 'white',
                  fontSize: '1rem'
              },
              '& .MuiDataGrid-menuIconButton': {
                  // make icon color white
                  color: 'white',
                  backgroundColor: 'transparent',
                  '&:hover': {
                      backgroundColor: 'transparent'
                  }
              },
              '& .MuiDataGrid-menuIcon': {
                  // make icon color white
                  color: 'white',
                  backgroundColor: 'transparent',
                  '&:hover': {
                      backgroundColor: 'transparent'
                  }
              },
              // chage color of sort icon color to white
              '& .MuiDataGrid-sortIcon': {
                  color: 'white'
              },
              // remove cell border on  click
              '& .MuiDataGrid-cell:focus-within': {
                  outline: 'none'
              },
              // remove hide column and manage columns
              '& .MuiDataGrid-columnHeader' : {
                  backgroundColor : 'transparent'
              },
              height: '80vh',
              "& .MuiDataGrid-virtualScrollerRenderZone": {
                  "& .MuiDataGrid-row": {
                    "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                  }
                }
                ,
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: '#3f51b5',
                  color: "white",
                  fontSize: 16
                }
          }}
          />}
    </Grid>
    </Grid>
    </>
  );
}

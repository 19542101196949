import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { Typography, Box} from '@mui/material';
import { useNavigate, useLocation} from 'react-router-dom';
import GroupsIcon from '@mui/icons-material/Groups';
import CategoryIcon from '@mui/icons-material/Category';
import Divider from '@mui/material/Divider';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ListIcon from '@mui/icons-material/List';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
export default function NestedList({props}) {
    const [open, setOpen] = React.useState(false);
    const location = useLocation()
    const navigate = useNavigate()
    const handleClick = () => {
        setOpen(!open);
    };
    const handleRouting = (path)=> {
        console.log(location.pathname)
        navigate(path)
    }
    const iconStyle = (link)=> {
        return { color: location.pathname === link ? '#1531ce' : '#fff' }
    }
    const data = [
        {
            header : 'Forms',
            subheader : [
                {
                    name : 'Webinar',
                    link: '/dashboard/forms/webinar',
                    Collapse : false,
                    icon:(color)=> <GroupsIcon style= {iconStyle('/dashboard/forms/webinar')} />
                },
                {
                    name : 'Category',
                    link: '/dashboard/forms/category',
                    Collapse : false,
                    sub : [
                        {
                            name : 'Form 2.1',
                            link : '/form2.1'

                        },
                        {
                            name : 'Form 2.2',
                            link : '/form2.2'
                        }
                    ],
                    icon: (color) => <CategoryIcon style={iconStyle('/dashboard/forms/category')} />
                },
                {
                    name : 'Speaker',
                    link: '/dashboard/forms/speaker',
                    Collapse : false,
                    icon:(color)=> <PersonAddAlt1Icon style={iconStyle('/dashboard/forms/speaker')} />
                },
                {
                    name : "Manage Users",
                    link: '/dashboard/forms/manageusers',
                    Collapse : false,
                    icon:(color)=> <PersonAddAlt1Icon style={iconStyle('/dashboard/forms/manageusers')} />
                }
            ]
        },
        {
            header : 'Data Manager',
            subheader : [
                {
                    name : 'Webinar',
                    link: '/dashboard/datamanager/webinar',
                    Collapse : false,
                    icon:(color)=> <ListIcon style={iconStyle('/dashboard/datamanager/webinar')} />
                },
                {
                    name : 'Speaker',
                    link: '/dashboard/datamanager/speaker',
                    Collapse : false,
                    icon:(color)=> <ListIcon style={iconStyle('/dashboard/datamanager/speaker')} />
                }
            ]
        },
        {
            header : 'Reports',
            subheader : [
                {
                    name : 'Sales',
                    link: '/dashboard/reports/sales',
                    Collapse : false,
                    icon:(color)=> <AssessmentIcon style={iconStyle('/dashboard/reports/sales')} />
                },
                {
                    name : 'Customers',
                    link: '/dashboard/reports/customers',
                    Collapse : false,
                    icon:(color)=> <AssessmentIcon style={iconStyle('/dashboard/reports/customers')} />
                },
                {
                    name : 'Subscribers',
                    link: '/dashboard/reports/subscribers',
                    Collapse : false,
                    icon:(color)=> <AssessmentIcon style={iconStyle('/dashboard/reports/subscribers')} />
                },
                {
                    name : 'Contact Us',
                    link: '/dashboard/reports/contact',
                    Collapse : false,
                    icon : (color)=> <AssessmentIcon style={iconStyle('/dashboard/reports/contact')} />
                },
                {
                    
                        name : 'Speaker Opportunities',
                        link: '/dashboard/reports/speakers-opportunities',
                        Collapse : false,
                        icon : (color)=> <AssessmentIcon style={iconStyle('/dashboard/reports/contact')} />
                    
                }
            ]
        }
    ]
    return (
       
        <List
            sx={{
                width: '100%', maxWidth: 360, color: 'white',
        }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            // subheader={
            //     <ListSubheader component="div" id="nested-list-subheader">
            //         Nested List Items
            //     </ListSubheader>
            // }
             defaultcollapseicon={<ExpandMore />}
             // remove background elevation 
             dense = {false}
            disablePadding = {true}
        >
            {data.map((item, index) => {
                return (
                    <Box key={index}
                        sx={{maxWidth: 360, bgcolor: 'transaparent', paddingBlock: '0.5rem'}}
                    >
                        {/* <ListItemButton>
                            <ListItemIcon>
                                <SendIcon />
                            </ListItemIcon>
                            {item.header}
                        </ListItemButton> */}
                        <Typography variant='h6'  
                        gutterBottom
                        sx={{paddingLeft: '2rem',
                        fontWeight: 'bold',
                        }}>{item.header}</Typography>
                        {item.subheader.map((sub, index) => {
                            return (
                                <Box key={index} 
                                    // sx={{ maxWidth: 360, bgColor: location.pathname === sub.link ? '#3f51b5' : '#3f51b5', paddingLeft: '2rem'}}
                                    style={{ maxWidth: 360,paddingLeft: '2rem'}}
                                >
                                    <ListItemButton
                                        // onClick={sub.Collapse ? handleClick : null}
                                        onClick={()=>handleRouting(sub.link)}
                                        style={{ color: location.pathname === sub.link ? '#1531ce' : 'inherit',
                                        // fontSize: location.pathname === sub.link ? '1.5rem' : '1rem',
                                        // fontWeight: location.pathname === sub.link ? 'bold' : 'normal',
                                         backgroundColor: location.pathname === sub.link ? '#ffffff' : 'transparent',
                                         borderRadius: '1rem'
                                    }}
                                    >
                                        <ListItemIcon>
                                           { sub.icon(
                                                  location.pathname === sub.link ? 'primary' : 'inherit'
                                           )}
                                        </ListItemIcon>  
                                        <ListItemText primary={
                                            <Typography variant='p' 
                                            fontWeight= {location.pathname === sub.link ? 'bold' : 'normal'}
                                            >
                                                {sub.name}
                                            </Typography>
                                        }
                                        />
                                         {sub.Collapse ? open ? <ExpandLess /> : <ExpandMore /> : null}
                                    </ListItemButton>
                                    {sub.Collapse ? <Collapse in={open} timeout="auto" unmountOnExit> 
                                        <List component="div" disablePadding>
                                            {sub.sub.map((sub, index) => {
                                                return (
                                                    <ListItemButton sx={{ pl: 4 }} key={index} onClick={()=> handleRouting}>
                                                        <ListItemIcon>
                                                            <StarBorder />
                                                        </ListItemIcon>
                                                        <ListItemText primary={sub.name} />
                                                    </ListItemButton>
                                                )
                                            })}
                                        </List>
                                    </Collapse> : null}
                                    
                                </Box>
                            )
                        })}
                        < Divider/>
                    </Box>
                )
               
            })}
        </List>
    );
}